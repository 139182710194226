
import { ChainMetadata } from "@polkawatch/ddp-client";

/**
 * Chain metadata is generic and dynamic
 * However there is a default initialization based on information known at compile time
 * Having the right defaults improves user experience
 */

const NPoSChainMetadata:ChainMetadata = {
    Name: '...',
    Token: '...',
    ID: undefined,
    HasStaking: true,
    IsParachain: false,
    ErasPerMonth: undefined,
    HasNominationPools: true,
    RelayChain: '...',
    TrackingSystem: 'tokens',
    BlockAuthoring: "babe"
}

const AURAChainMetaData: ChainMetadata = {
    Name: '...',
    ID: undefined,
    HasStaking: false,
    IsParachain: true,
    ErasPerMonth: undefined,
    HasNominationPools: false,
    TrackingSystem: 'blocks',
    BlockAuthoring: "aura"
}

/**
 * We pretty much know most of this structure at static rendering time,
 * So, we use this information to provide a better default
 * @param chain
 */
export function getDefaultMetadata(chain){
    switch (chain){
        case "polkadot": return {
            ... NPoSChainMetadata,
            Name: "Polkadot",
            RelayChain: "Polkadot",
            ID: 0,
            Token: "DOT",
            ErasPerMonth: 30
        }
        case "kusama": return {
            ... NPoSChainMetadata,
            Name: "Kusama",
            RelayChain: "Kusama",
            ID: 2,
            Token: "KSM",
            ErasPerMonth: 120
        }
        case "statemine": return{
            ... AURAChainMetaData,
            Name: "Statemine",
            RelayChain: "Kusama",
            Token: "KSM",
            ID: 2,
            ErasPerMonth: 120
        }
    }
    return NPoSChainMetadata;
}

export const AvailableChains = ["polkadot", "kusama", "statemine"];